import React, { useEffect } from 'react';
import { Container, LoginWrapper, Branding } from './login.styles';
import LoginContainer from './components/Login.container';
import ForgotPassword from './components/ForgotPassword/ForgotPassword.container';
import ResetSuccess from './components/ResetSuccess';
import EmailSent from './components/EmailSent/EmailSent.container';
import ResetPassword from './components/ResetPassword/ResetPassword.container';
import LinkExpired from './components/LinkExpired';
import { loginPages as pages } from './utils/pageConfig';
import { RESET_PASSWORD_PATHNAME, SET_PASSWORD_PATHNAME } from './utils/pageConfig';
import { basePath } from '../../services/utils';
import { MAINTENANCE_MESSAGE_JSX } from '../../utils/translations';

const LoginPage = ({ currentPage, changeLoginPageActionCreator, isResetTokenValidActionCreator, isMaintenance }) => {
    useEffect(() => {
        const locationFromWindowPathname = window.location.pathname;
        if (
            locationFromWindowPathname === RESET_PASSWORD_PATHNAME ||
            locationFromWindowPathname === SET_PASSWORD_PATHNAME
        ) {
            const searchParams = new URL(window.location).searchParams;
            const resetToken = searchParams.get('tkn');
            isResetTokenValidActionCreator(resetToken);
        }
    }, []);

    let displayedPage;

    switch (currentPage) {
        case pages.LOGIN:
            displayedPage = (
                <LoginContainer goToForgotPassword={() => changeLoginPageActionCreator(pages.FORGOT_PASSWORD)} />
            );
            break;
        case pages.FORGOT_PASSWORD:
            displayedPage = <ForgotPassword goToLogin={() => changeLoginPageActionCreator(pages.LOGIN)} />;
            break;
        case pages.RESET_SUCCESS:
            displayedPage = (
                <>
                    <ResetSuccess />
                    <LoginContainer goToForgotPassword={() => changeLoginPageActionCreator(pages.FORGOT_PASSWORD)} />
                </>
            );
            break;
        case pages.EMAIL_SENT:
            displayedPage = <EmailSent />;
            break;
        case pages.RESET_PASSWORD:
            displayedPage = <ResetPassword />;
            break;
        case pages.LINK_EXPIRED:
            displayedPage = (
                <LinkExpired goToForgotPassword={() => changeLoginPageActionCreator(pages.FORGOT_PASSWORD)} />
            );
            break;
        default:
            break;
    }
    return (
        <Container background={`${basePath}/visuals/login_bg.png`}>
            <LoginWrapper>
                <Branding logo={`${basePath}/visuals/toluna_logo2025.png`} />
                {isMaintenance ? MAINTENANCE_MESSAGE_JSX : displayedPage}
            </LoginWrapper>
        </Container>
    );
};
export default LoginPage;
