import React from 'react';

export const TRANSLATION_TEXT = {
    //**** GENERAL TEXT */
    GENERAL_SUBMIT_OK_TEXT: 'Ok',
    CANCEL_TEXT: 'Cancel',
    YES_TEXT: 'Yes',
    DASHBOARD: 'Dashboard',
    //**** Modal */
    OPEN_MODAL_TEXT: 'Open Modal',
    EXPORT_READY_TEXT: 'Your export is ready!',
    DOWNLOAD_CONFIRM_TEXT: 'Are you sure you want to download this file?',
    //**** Footer */
    RANKING_FOOTER_TEXT: '*Per month / per quarter, does not follow logic',
    INFO_PICTOGRAM_TEXT: `YTD = From beginning of current year until last completed quarter. \n E.g. : if last set of data = April 2022 --> YTD = Q1 2022`,
    INFO_YTD_TEXT: 'YTD',
    //**** Filters */
    SEARCH_BY_TEXT: 'Search by',
    SELECT_ALL_TEXT: 'Select All',
    SEARCHED_TEXT: 'Searched',
    FILTERED_DATA_TEXT: 'Filtered data',
    FILTERED_DATA_AVERAGE_TEXT:
        'The average available on this report is the arithmetic average between the different compared ads',
    DISPLAY_BUTTON_ADDITIONAL_INFO_AD_COMPARISON: 'Comparison is possible up to 5 ads',
    SIDE_FILTERS_CLEAR_TEXT: 'Clear all filters',
    //**** EXPORT */
    EXPORT_TEXT: 'Export',
    EXPORT_UM_TEXT: 'Action Logs Export',
    //**** AD Details Metrics */
    CAMPAIGN_DETAILS_TITLE_TEXT: 'COPY DETAILS',
    COPY_RATING_EMOTIONS_TITLE_TEXT: 'COPY RATING - EMOTIONS',
    CAMPAIGN_DIAGNOSTICS: 'COPY DIAGNOSTICS',
    MINIMUM_OPTIONS_SELECTED: 'Please select between 2 and 5 Copy Names to view available data.',
    OPTIONS_WERE_SELECTED: 'You can now display the selected Copy Names.',
    //**** Campaign Details */
    COPY_INFO_TEXT: 'Copy Information',
    COUNTRY_TEXT: 'Country',
    COPY_NAME_TEXT: 'Copy Name',
    TV_GRP_TEXT: 'GRP',
    TV_CAMPAIGN_DATES: 'Campaign Dates',
    AIRING_TEXT: 'Airing',
    BPT_INFO_TEXT: 'BPT Information',
    NO_INTERVIEWS_TEXT: 'Number of interviews',
    BPT_DATES_TEXT: 'BPT Dates',
    TO_TEXT: 'to',
    //**** DropDown Component */
    SEARCH_TEXT: 'Search',
    APPLY_TEXT: 'Apply',
    //**** Map Component */
    WARNING: 'Warning!',
    SURVEY_FIELDWORK_INFO: 'Intermediary Results (coming from live fieldwork & unweighted)',
    AD_TESTER_PROD_TEXT: ' Ad(s)',
    BPT_PRODUCTS_TEXT: ' BPT survey(s)',
    HEADER_TEXT: 'Country',
    //**** Editorial Component */
    EDITORIAL_TITLE_TEXT: 'EDITORIAL',
    GOT_IT_TEXT: 'Got it',
    //**** User Dropdown Panel Component */
    ADMIN_TEXT: 'Admin',
    BACK_OFFICE_TEXT: 'BackOffice',
    LOGOUT_TEXT: 'Log Out',
    //**** Landing Page */
    LOGO_WRAPPER_TITLE_TEXT: 'Brand Preference Tracker Platform',
    LOGO_WRAPPER_CONTENT_TEXT: 'Welcome to the web platform that allows an efficient way to access research results!',
    HOW_TO_TEXT: 'How to use this tool',
    //**** Report Options Component */
    TITLE_SURVEY_TEXT: 'Survey Fieldwork',
    TITLE_ADTESTER_TEXT: 'Adtester',
    TITLE_BPT_TEXT: 'Brand Preference Tracker',
    TITLE_FILTER_SEGMENT_LIBRARY_TEXT: 'Filter Segment Library',
    //*Login Component
    ERROR_TEXT_LOGIN: {
        400: 'Your email or password is incorrect. Please try again',
        500: 'The application has encountered an unknown error',
    },
    EMAIL_SENT_TEXT: 'Email sent to',
    EMAIL_SENT_MESSAGE:
        'In few moments, you will receive your password reset email. Simply follow the instructions in order to reset your password.',

    FORGOT_PASSWORD_TEXT: 'Trouble Logging In?',
    FORGOT_PASSWORD_MESSAGE_TEXT:
        "Enter your username or email and we'll send you a link to get back into your account.",
    FORGOT_PASSWORD_INVALID_EMAIL_TEXT: '* invalid email please try again',
    FORGOT_PASSWORD_SUBMIT_TEXT: 'Reset Password',
    FORGOT_PASSWORD_BACK_LOGIN_TEXT: 'Back to login',

    RESET_PASSWORD_TEXT: {
        SET_PASSWORD: 'Set your password',
        RESET_PASSWORD: 'Reset your password',
    },
    RESET_PASSWORD_MESSAGE_TEXT: 'Please enter a new password in the fields bellow',
    RESET_PASSWORD_INPUT_TEXT: 'Type your new password',
    RESET_PASSWORD_REPEAT_INPUT_TEXT: 'Repet your new password',
    RESET_PASSWORD_SUBMIT_TEXT: 'Reset your Passowrd',
    RESET_PASSWORD_SUCCESS_TEXT: {
        SET_PASSWORD: 'Your password was successfully set!',
        RESET_PASSWORD: 'Your password was successfully reset!',
    },
    RESET_PASSWORD_SUCCESS_TEXT_SECONDARY: 'Please connect with your new credentials',
    RESET_PASSOWRD_SUBMIT_TEXT: {
        SET_PASSWORD: 'Set your password',
        RESET_PASSWORD: 'Reset your password',
    },

    LINK_EXPIRED_TITLE_TEXT: {
        RESET_PASSWORD: 'Whoops, your password reset link has expired.',
        SET_PASSWORD: 'Whoops, your invitation link has expired.',
    },
    LINK_EXPIRED_MESSAGE_TEXT: {
        RESET_PASSWORD: 'For added security, links expire after 24 hours.',
        SET_PASSWORD: 'For added security, links expire after 7 days.',
    },

    LINK_EXPIRED_SUBMIT_TEXT: 'Click here',
    //Both texts are indeed identical. But maybe they will change at a certain point and better have the functionality built in and only the change of the text here will be required on further updates.
    LINK_EXPIRED_ATTENTION_TEXT: {
        RESET_PASSWORD: 'to send a new password reset link and make sure you reset your password within 24 hours.',
        SET_PASSWORD: 'to send a new password reset link and make sure you reset your password within 24 hours.',
    },
    LOGIN_EMAIL_INPUT_TEXT: 'Please enter your email',
    LOGIN_PASSWORD_INPUT_TEXT: 'Please enter your password',
    LOGIN_ERROR_LABEL_USER_TEXT: 'Please enter a valid username!',
    LOGIN_ERROR_LABEL_PASSWORD_TEXT: 'Please enter your password!',
    LOGIN_SUBMIT_TEXT: 'Connect',
    LOGIN_RESET_PASS_BUTTON_TEXT: 'Forgot my password',
    /****KPIS Table Component */
    SAMPLE_RECOGNIZED_TEXT: 'Sample who recognized the campaign and attribute to Axa  ',
    SAMPLE_NOT_RECOGNIZED_TEXT: 'Sample who has not recognized the campaign or not attributed to Axa ',
    SAMPLE_SIZE_LOW: 'sample size is too low (n<50)',
    //**** Home Section Config */
    ADTESTER_PARAGRAPHS_TEXT: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vitae finibus enim. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia nunc ante, a sagittis diam tincidunt sit amet. Vivamus lacinia placerat felis a euismod. Curabitur massa magna, eleifend ut quam sit amet, elementum dignissim elit. ',
        'In fermentum lacinia risus, id ultrices diam congue eget. Phaselluse get aliquam ex. Aenean eget orci vitae dolor interdum mollis ut idmetus. Aenean metus elit, condimentum non sodales quis, fringilla vitae libero. Nulla nulla ipsum, hendrerit at fringilla et, gravida nec orci. Nam vel tempor elit, eu aliquet nisi. Suspendisse potenti. Duis placerat, ex id aliquam pharetra, ligula elit mollis lorem, ut hendrerit arcu purus ac sem. Donec lobortis vitae nunc eget sagittis. Nam ut ex porta, vehicula lorem nec, mollis elit. ',
    ],
    FILTER_SEGMENT_LIBRARY_PARAGRAPHS_TEXT: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vitae finibus enim. Suspendisse potenti. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacinia nunc ante, a sagittis diam tincidunt sit amet. Vivamus lacinia placerat felis a euismod. Curabitur massa magna, eleifend ut quam sit amet, elementum dignissim elit. Curabitur a lobortis dolor, sed placerat risus. Ut porta dui lorem, eleifend auctor est rhoncus at. Phasellus eu euismod quam, non eleifend ligula. Maecenas rhoncus nulla.',
        'In fermentum lacinia risus, id ultrices diam congue eget. Phaselluse get aliquam ex. Aenean eget orci vitae dolor interdum mollis ut idmetus. Aenean metus elit, condimentum non sodales quis, fringilla vitae libero. Nulla nulla ipsum, hendrerit at fringilla et, gravida nec orci. Nam vel tempor elit, eu aliquet nisi. Suspendisse potenti. Duis placerat, ex id aliquam pharetra, ligula elit mollis lorem, ut hendrerit arcu purus ac sem. Donec lobortis vitae nunc eget sagittis. Nam ut ex porta, vehicula lorem nec, mollis elit. Praesent ornare at risus quis imperdiet. Maecenas feugiat semper libero, vel tristique eros porta non. Nunc ultricies turpis eu lobortis fringilla. Curabitur  quis congue massa. Quisque placerat erat vulputate lectus tempor, et viverra dolor tristique. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    ],
    //**** Error Notification Message */
    ERROR_NOTIFICATION_MAIN_MESSAGE: 'Data request failed!',
    ERROR_NOTIFICATION_SECONDARY_MESSAGE: 'Sorry, there was a problem with your request.',
    //**** Error Notification Message */
    SESSION_EXPIRED_MODAL_TITLE_TEXT: 'Session Expired',
    SESSION_EXPIRED_MODAL_BODY_TEXT: 'Your session has expired, please login again to access the portal.',
    AD_TESTER_METHODOLOGY_TEXT: 'Ad Tester methodology',
    //**** IFRAME */
    NO_DATA_AVAILABLE_TEXT: 'No data is currently available.',
    //**** TOOLBOX */
    TEMPLATE_ADTESTER_QUESTIONNAIRE_TEXT: 'Template questionnaire for Ad Tester',
    AXA_BPT_TEXT: 'Axa BPT Value for Money matrix',
    AXA_BPT_METHODOLOGY_TEXT: 'Axa BPT Methodology',
    TOOLBOX_TEXT: 'Toolbox',

    //**Backoffice */
    ERROR_DETAILS_TEXT: 'Error details',
    OVERWRITE_TITLE_TEXT: 'Do you confirm?',
    OVERWRITE_BODY_TEXT: 'Are you sure you want to overwrite this file ?',
    OVERWRITE_CONFIRM_TEXT: 'Yes, overwrite',
    FILE_IN_PROGRESS_TEXT: 'File processing still in progress. Are you sure ?',
    SEARCH_BY_PROJECT_NAME_TEXT: 'Search',
    BACKOFFICE_TITLE: 'BACKOFFICE',
    BACKOFFICE_SUBTITLE_OUT_OF: ' out of ',
    BACKOFFICE_SUBTITLE_CACHED_STATUS: ' frequently used filter combinations have been cached',
    LOADING_DATA: 'Loading Data...',
    METADATA_TITLE: 'METADATA UPLOAD',
    DATA_TITLE: 'DATA UPLOAD',
    CONFIRMIT_TITLE: 'CONFIRMIT DATA UPLOAD',
    MEDIA_TITLE: 'MEDIA UPLOAD',
    ERROR_BUTTON_TEXT: 'View Error',
    ERROR_MESSAGE_UPLOAD_URL: 'Could not obtain upload URL. Please try again.',
    ERROR_MESSAGE_UPLOAD_FILE: 'Could not upload file. Please try again.',

    //**User management */
    USER_MANAGEMENT_TITLE: 'User Management',
    USER_MANAGEMENT_SEARCH_TEXT: 'Search user',
    DEPENDENT_OPTION_TEXT: 'BPT option not selected.',
    VALIDATION_MESSAGE: txt => `Please select at least one ${txt} option.`,
    DEPENDENT_BPT_TEXT: 'Dependent BPT',
    //** AdTester -> Overview */
    COMPARE_ADS: 'Compare these',
    MAX_ADS_SELECTED_WARNING_HOVER: 'Maximum number of selected ads reached',
    OVERVIEW_TABLE_INDIVIDUAL_ADS: 'View Individual Ads',
    OVERVIEW_TABLE_ADS_COMPARISON: 'View Ads Comparison',

    //** Awareness */
    BARCHART_AWARENESS_TITLE: 'AXA -  Overall',
    //**Preference analysis */
    HEADER_RANK_TEXT: 'Rank',
    HEADER_OVERALL_TEXT: 'Overall',
    RANK_CHART_DROPDOWN_DEFAULT: 'Select a brand',
    //**BRAND PREFERENCE TRACKER -> PreferenceAnalysis -> Awareness (Legend) */
    BPT_AWARENESS_LEGEND_SIGNIFICANTLY_HIGHER: 'Significantly higher 95% conf level vs. previous wave / FY',
    BPT_AWARENESS_LEGEND_SIGNIFICANTLY_LOWER: 'Significantly lower 95% conf level vs. previous wave / FY',
    BPT_AWARENESS_LEGEND_RANK: 'Rise / Fall in Rank',
    //**BRAND PREFERENCE TRACKER -> PreferenceAnalysis -> EvolutionLegend */
    BPT_EVOLUTION_LEGEND_SIGNIFICANTLY_HIGHER: 'Filtered result is over global results',
    BPT_EVOLUTION_LEGEND_SIGNIFICANTLY_LOWER: 'Filtered result is below global results',
    BPT_EVOLUTION_LEGEND_DESCRIPTION: 'Difference (number of points) between filtered results and global results.',

    //**BRAND PREFERENCE TRACKER -> PreferenceAnalysis -> Ad Recall*/
    BPT_LOB_LEGEND_TOGGLE_ALL: 'Check/uncheck all',
    BPT_LOB_LEGEND_TOP_FIVE: 'Top 5',
    BPT_EVOLUTION_METRIC_SYMBOL: 'Pts',
    //*Competitive Media*//
    COMPETITVE_MEDIA_TEXT: 'Competitive Media',
    COMPETITIVE_MEDIA_NOT_AGGREGATORS_INVESTMENT_NOTE:
        '*All numbers reported in this section do not include aggregators investment',
    COMPETITIVE_MEDIA_AGGREGATORS_INVESTMENT_NOTE: '*Numbers reported in this section include aggregators investment',
    CM_AGGREGATOR_STACKEDBARCHART_YAXIS_TITLE: 'Spend (M€)',
    INVESTMENT_SEASONALITY_KEY_COMPETITORS: 'Key Competitors',
    INVESTMENT_SEASONALITY_TOP_20: 'Top 20 Competitors',
    INVESTMENT_SEASONALITY_TOTAL_MARKET: 'Total Market',
    REPORT_UPDATE_STATS: 'This report is updated twice a year, when H1 & H2 results are available.',

    //**TRANSVERSAL PAGE TEXT*/
    TRANSVERSAL_VIEW_TEXT: 'TRANSVERSAL VIEW',
    DISPLAY_MODE_SHOW_ALL: 'Show All',
    DISPLAY_MODE_SHOW_CHARTS: 'Show Charts',
    DISPLAY_MODE_SHOW_RANKINGS: 'Show Rankings',
    ERROR_BOUNDARY_MESSAGE: 'Something went wrong here with displaying this part.',
    VIEW_ALL_EVOLUTION_METRICS: 'View All Evolution Metrics',
    INFO_TEXT_ERROR: 'Info text invalid',
};

//**BRAND PREFERENCE TRACKER -> PreferenceAnalysis -> Considertation Subsections */
export const CONSIDERATION_SUBSECTIONS_TITLE = (brand, considerText) => {
    switch (considerText) {
        case 'Consider':
            return (
                <>
                    THEY KNOW {brand.toUpperCase()} AND <strong>CONSIDER US</strong> FOR PURCHASE. WHY?
                </>
            );
        case 'NotConsider':
            return (
                <>
                    THEY KNOW {brand.toUpperCase()} BUT <strong>DO NOT CONSIDER</strong> US FOR PURCHASE. WHY?
                </>
            );
        default:
            return;
    }
};

export const CONSIDERATION_SUBSECTIONS_SUBTITLE_TEXT = (brand, considerText) => {
    switch (considerText) {
        case 'Consider':
            return (
                <>
                    Among people who know {brand} brand AND consider us for purchase they say doing so because (3
                    principal reasons):
                </>
            );
        case 'NotConsider':
            return (
                <>
                    Among people who know {brand} brand but do not consider us for purchase they say doing so because (3
                    principal reasons):
                </>
            );
        default:
            return;
    }
};
export const MAINTENANCE_MESSAGE_JSX = (
    <div style={{ padding: '0px 40px', textAlign: 'center', fontWeight: '700', color: '#243b5f' }}>
        <p>Dear User,</p>
        <p>The system is currently closed for maintenance. It will be available again today after 3pm. </p>
        <br />
        <p>We apologise for the inconvenience. Thanks</p>
    </div>
);

export const AD_TESTER_HOMEPAGE_JSX = (
    <div>
        <p>Ad testers are standard post tests allowing an evaluation of a campaign.</p>
        <br />
        <p>This is based on:</p>
        <ul className="mainList">
            <li>
                <p className="indent"> 4 standard questions </p>
                <ul className="nestedList" style={{ fontWeight: '500' }}>
                    <li>
                        <span style={{ fontWeight: 'bold' }} />
                        Recognition
                    </li>
                    <li>
                        <span style={{ fontWeight: 'bold' }} />
                        Attribution (among people who have recognised the ad)
                    </li>
                    <li>
                        <span style={{ fontWeight: 'bold' }} />
                        Likeability
                    </li>
                    <li>
                        <span style={{ fontWeight: 'bold' }} />
                        Call to action
                    </li>
                </ul>
            </li>
            <li>
                <p className="indent">Recognition & reach ratio</p>
            </li>
            <li>
                <p className="indent">Exposure to unbranded copy </p>
            </li>
            <li>
                <p className="indent">Copy rating emotions </p>
            </li>
            <li>
                <p className="indent">Optional main message understanding, reasons for liking (open-ended)</p>
            </li>
        </ul>
    </div>
);
export const COMPETITIVE_MEDIA_JSX = (
    <div className="mainCM">
        <p>Media competitive part gather a synthetic view of the estimated offline media spends in each country.</p>
        <p>Those numbers are built based on each local reference data provider (i.e. Nielsen, Kantar, AdmanGo, etc.)</p>
        <p>The process to build those numbers is the following:</p>
        <ol>
            <li>
                Local media agency gather the data and pre-process them:
                <ul>
                    <li>Check the data consistency with their market knowledge</li>
                    <li>
                        Calculate the conversion from growth to net (conversion rate is provided by each local agency)
                    </li>
                    <li>
                        Apply any local specificities as validated with local AXA Teams (i.e. Remove non relevant
                        competitors)
                    </li>
                </ul>
            </li>
            <li>
                All the data are sent to WPP who aggregate all the numbers in a database sent to Harris Interactive (for
                Germany, France, UK and Hong-Kong Publicis provide the number to WPP)
            </li>
            <li> Harris Interactive team load the database on the platform</li>
        </ol>
        <p>
            <span> &#10132;</span> The process is designed to ensure that media agency are accountable for the numbers
            present on this tool.
        </p>
        <p>
            <span> &#10132;</span> The database it to be updated twice a year : early April for FY results and early
            October for H1 results.
        </p>
        <p>
            <b>
                <span> &#10132;</span> Warning:
            </b>
            <ul>
                <li>All results are in net estimated</li>
                <li>As of now the results are not covering digital spends (topic under investigation)</li>
            </ul>
        </p>
    </div>
);
export const EDITORIAL_TEXT_JSX = (
    <div>
        <p>
            Welcome to the web platform that allows an efficient way to access research results based on a dynamic
            generation of results, with advanced filtering and export capabilities
        </p>
        <p style={{ padding: '0', marign: '0' }}>It allows:</p>
        <ul style={{ fontWeight: '500', padding: '0', paddingLeft: '20px' }}>
            <li>Live follow-up of the fieldworks’ progress</li>
            <li>Access to BPT survey results</li>
            <li>Access to the Ad Tester tests results</li>
        </ul>
        <p> This platform provides the following services:</p>
        <ul style={{ fontWeight: '500', padding: '0', paddingLeft: '20px' }}>
            <li>To consult results online (tabs or charts)</li>
            <li>To access specific perimeters (based on sub populations)</li>
            <li>
                To deep-dive into results (compare, filter, view results on spot period or on trends) and export results
            </li>
        </ul>

        <p style={{ fontWeight: '500' }}>
            <strong>Note:</strong> At this stage, only part of the functionalities have been deployed – we will let you
            know when additional features are available. Stay tuned!
        </p>
    </div>
);

export const BPT_SECTION_MAIN_JSX = (
    <>
        <section className="mainBpt">
            <h1>What...</h1>
            <ul>
                <li>AXA Group global brand study conducted by Harris Interactive</li>
                <li>Based on a consistent global methodology but customized to local specificities</li>
                <li>Key measures include brand awareness, consideration, preference and image</li>
                <li>AXA measured alongside key competitors</li>
                <li>Categories covered depend on the country</li>
            </ul>
            <h1>Why...</h1>
            <ul>
                <li>Regular measure of brand equity</li>
                <li>Helps Axa understand competitive brand strength and unpick our campaign impact/efficiency</li>
                <li>
                    Works alongside with local tracking, customer experience tracking and business metrics to provide a
                    rounded understanding of Axa brand performance
                </li>
            </ul>
        </section>
    </>
);

export const BPT_SECTION_SECONDARY_JSX = (
    <>
        <section className="secondaryBpt">
            <div className="target">
                <span />
                <h1>Target</h1>
                <p>
                    People aged 18-65 y.o. holders of at least one insurance product (P&C, Savings & Investments,
                    Banking).
                </p>
            </div>
            <div className="sample">
                <span />
                <h1>Sample Size</h1>
                <p>Variable depending the country</p>
                <p>Quotas on gender, age, social class</p>
            </div>
            <div className="interviews">
                <span />
                <h1>Interviews</h1>
                <p>Interviews are conducted online by CAWI (Computer Assisted Web Interviews)</p>
                <p>Questionnaire length of approx 20 minutes (without Ad Tester evaluation).</p>
            </div>
        </section>
    </>
);

export const BROWSE_FILE = (
    <>
        Select <strong>"Browse"</strong> to upload a file from your computer
    </>
);

export const FOOTER_JSX = (
    <>
        Powered by{' '}
        <a target="_blank" href={'https://tolunacorporate.com/'}>
            Toluna.
        </a>{' '}
    </>
);
